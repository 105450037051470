<template>
  <main>
    <header-black />
    <contact-map />
  </main>
</template>

<script>
export default {
  name: 'ContactsPage',
  components: {
    HeaderBlack: () => import('@/components/HeaderBlack'),
    ContactMap: () => import('@/components/ContactMap')
  }
}
</script>
